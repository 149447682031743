import React, { useCallback, useEffect, useState } from 'react';

import TextInput from "../components/widgets/TextInput";

import { TextCardFilter } from "../services/card-filters";

import './CardTextFilter.css';

/**
 * @returns a component for filtering cards by text, with options for 
 * filtering by card name, card type, and card oracle text.
 */
export default function CardTextFilter({ filter, disabled, onChange }) {
  const [isFilterName, setFilterName] = useState(true);
  const [isFilterType, setFilterType] = useState(true);
  const [isFilterText, setFilterText] = useState(true);
  const [isEmpty, setEmpty] = useState(true);

  // update the isEmpty state when the provided filter changes
  useEffect(() => {
    // isEmpty when no filter or when the filter's text value is empty
    setEmpty(!filter?.value.trim().length);
  }, [filter]);

  const handleTextSubmit = useCallback((text) => {
    if (text.trim().length) {
      const filter = new TextCardFilter(text, isFilterName, isFilterType, isFilterText);
      onChange(filter);
    }
    else onChange(/* undefined */);
  }, /* dependencies = */ [ onChange, isFilterName, isFilterType, isFilterText ]);

  function handleTextChange(text) {
    // turn isEmpty off as soon as the <TextInput> is non-empty
    // but DON'T turn it off as soon as it is empty (wait for `handleTextSubmit`)
    if (text.trim().length) setEmpty(false);
  }

  function handleFilterNameText(event) {
    const filterByName = event.target.checked;
    // update the local component state
    setFilterName(filterByName);
    if (filter) {
      // update the filter and propagate the change
      filter.setFilterByName(filterByName);
      onChange(filter);
    }
  }

  function handleFilterTypeText(event) {
    const filterByType = event.target.checked;
    // update the local component state
    setFilterType(filterByType);
    if (filter) {
      // update the filter and propagate the change
      filter.setFilterByType(filterByType);
      onChange(filter);
    }
  }

  function handleFilterOracleText(event) {
    const filterByText = event.target.checked;
    // update the local component state
    setFilterText(filterByText);
    if (filter) {
      // update the filter and propagate the change
      filter.setFilterByText(filterByText);
      onChange(filter);
    }
  }

  return <><TextInput placeholder='Filter Cards by Text' 
      className="card-text-filter" 
      disabled={disabled} 
      value={filter?.value} 
      onChange={handleTextChange}
      onSubmit={handleTextSubmit}
    />
    <div className={`card-text-filter-types ${isEmpty ? 'hidden' : ''}`}>
      <label>
        <input type="checkbox" id="card-text-filter-name" 
            disabled={disabled || isEmpty} 
            checked={isFilterName}
            onChange={handleFilterNameText}
          />
        Name
      </label>
      <label>
        <input type="checkbox" id="card-text-filter-type" 
            disabled={disabled || isEmpty} 
            checked={isFilterType} 
            onChange={handleFilterTypeText}
          />
        Type
      </label>
      <label>
        <input type="checkbox" id="card-text-filter-text" 
            disabled={disabled || isEmpty} 
            checked={isFilterText} 
            onChange={handleFilterOracleText}
          />
        Text
      </label>
    </div>
  </>;

}